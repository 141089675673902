export const EMPLEADOS = "/listausuarios";
export const EMPLEADO = "/empleado";
export const EMPLEADO_MODIFICAR = "/empleadomodificar";
export const ESTRUCTURA = "/estructura";
export const ESTRUCTURA_MODIFICAR = "/estructuramodificar";
export const INFO_ESTRUCTURA = "/info_estructura";
export const DETALLE_COMUNIDAD = "/detalle_comunidad";
export const DETALLE_GREMIO = "/detalle_proveedor";
export const ANADIR_GREMIO = "/addgremio";
export const ANADIR_USUARIO = "/addusuario";
export const ANADIR_ROL = "/addrol";
export const DETALLE_ROL = "/detalle_rol";
export const DETALLE_USUARIO = "/detalle_usuario";
export const REGISTRO_HORARIO = "/registro_horario";
export const DETALLE_ACTIVIDAD = "/detalle_actividad";
export const ANADIR_ACTIVIDAD = "/addactividad";
export const DETALLE_INCIDENCIA = "/detalle_incidencia";
export const ANADIR_INCIDENCIA = "/addincidencia";
export const LISTA_VOTACIONES = "/votaciones";
export const DETALLE_VOTACION = "/detalle_votacion";
export const DETALLE_VOTACION_JUNTA = "/detalle_votacion_junta";
export const ESTRUCTURAS = "/listaestructuras";
export const DETALLE_DESPACHO = "/detalle_despacho";
export const CENTROS_DE_TRABAJO = "/centros_de_trabajo";
export const ANADIR_CENTRO_DE_TRABAJO = "/anadir_centro_de_trabajo";
export const ANADIR_DESPACHO = "/anadir_despacho";
export const DETALLE_CENTRO_TRABAJO = "/detalle_centro_trabajo";
export const IMAGEN_QR_JUNTA = "/img_qr_junta";
export const IMAGEN_QR_CENTROTRABAJO = "/img_qr_centrotrabajo";
export const RECIBOS = "/recibos";
export const RECIBOS_COMU = "/recibos_comu";

export const TUTORIAL = "/tutorial";

export const PRUEBAS = "/listadopruebas";
export const PRUEBA = "/prueba";
export const PRUEBA_MODIFICAR = "/pruebamodificar";

export const PRUEBA_FORMULARIO = "/pruebaformulario";

export const EVALUACIONES = "/listadoevaluaciones";
export const EVALUACION = "/evaluacion";
export const EVALUACION_MODIFICAR = "/evaluacionmodificar";
export const EVALUADOS = "/listadoevaluados";

export const INFORMES = "/informes";

export const SIGN_UP = "/session/signup";

export const SIGN_IN = "/session/signin";

export const FORGOT = "/session/forgot-password";
export const NOTFOUND = "/session/404";

export const HOME = "/home";
export const ACCOUNT = "/account";
export const ADMIN = "/admin";
export const INCI = "/inci";
export const MASTER = "/datosmaestros";
export const PASSWORD_FORGET = "/pw-forget";
export const ACTIVIDADES = "/actividades";
export const COMUNIDADES = "/comunidades";
export const GREMIOS = "/proveedores";
export const DOCUMENTOS = "/documentos";
export const USUARIOS = "/usuarios";
export const ROLES = "/roles";
export const ZONAS = "/zonascomunes";
export const VOTACION = "/votaciones";
export const JUNTA = "/junta";
export const JUNTASVIRTUALES = "/juntas";
export const CATEGORIAS = "/categorias";
export const PRIVACIDAD = "/privacidad";
export const AVISO_LEGAL = "/aviso_legal";
export const POLITICA_COOKIES = "/politica_cookies";
export const POLITICA_PRIVACIDAD = "/politica_privacidad";
export const LOPD = "/lopd";
export const SUPERUSER = "/superuser";
export const FACTURACION = "/facturacion";
export const PORTA = "/portalpruebammm";
export const INCI_GREMIOS = "/inci_gremios/:id";

export const LISTA_ZONASCOMUNES = "/zonascomunes";
export const ANADIR_ZONACOMUN = "/addzonacomun";
export const DETALLE_ZONACOMUN = "/detalle_zonacomun";
export const HISTORIAL_ZONACOMUN = "/historial_reservas";
export const ACCESOS_ZONACOMUN = "/registro_accesos";
export const CONTACTO = "/contact/:contactId";

export const ANADIR_JUNTA = "/addjunta";
export const DETALLE_JUNTA = "/detalle_junta";
export const VIDEO_JITSI = "/video_jitsi";
export const QR_JUNTA = "/qr_junta";

export const LISTA_CONTACTO = "/contact-list";

export const FORM_CONTACTO = "/contact-form";
export const ACUERDO = "/deal/:dealId";
export const LISTA_ACUERDO = "/deal-list";
export const FORM_ACUERDO = "/deal-form";

export const LISTA_COMUNIDADES = "/listacomunidades";
export const COMUNIDAD = "/comunidad";
export const USUARIO = "/usuario";

export const LISTA_INCIDENCIAS = "/incidencias";
export const ADMINISTRACION = "/administracion";
export const NOTIFICACIONES = "/notificaciones";
export const NOTIFICACION_BUSCADOR = "/notificaciones/buscador";

export const DETALLE_NOTIFICACION = "/detalle_notificaciones";
export const DESPACHO = "/adminDesk";
export const ANADIR_VOTACION = "/addvotacion";

export const ANADIR_EMPLEADO = "/anadir_empleado";
export const CONGRESO = "/congreso_cnaf2024";
