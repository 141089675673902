import React from "react";
import { useStore } from "react-redux";

/*
import { SiHtml5, SiJavascript, SiCss3, SiMarkdown } from "react-icons/si";
import { FaFileExcel, FaFileWord, FaFilePdf, FaFileImage } from "react-icons/fa";
import { AiFillFile } from "react-icons/ai";
import { FiFolder } from "react-icons/fi";
*/
import obtenerIconoPorExtension from "../helpers/IconosFicheros";
import { MdHelpOutline } from "react-icons/md";
import Tooltip from "react-tooltip-lite";
//import { Hidden } from "@mui/material";
let isFile = false;
const Emulador = ({ archivosEnCarpetaArborist, Identificador, docusUsuario }) => {
  const store = useStore();
  const lengu = store.getState().lenguajes;
  const diccionario = lengu.diccionario;
  function truncarCadena(cadena, longitudMaxima) {
    if (cadena.length <= longitudMaxima) {
      return cadena;
    } else {
      return cadena.slice(0, longitudMaxima) + "...";
    }
  }

  return (
    <div className="container-emulador" style={{ marginLeft: "15px", marginTop: "20px" }}>
      <Tooltip content={diccionario["tooltip_emulador"]}>
        <h5 style={{ marginBottom: "10px", paddingLeft: "10px" }}>
          {diccionario["emulador"]} <MdHelpOutline color="blue" style={{ fontSize: "28px" }} />
        </h5>
      </Tooltip>
      <div
        className="emulador"
        style={{
          width: "325px",
          height: "580px",
          border: "16px solid #333",
          borderRadius: "24px",
          overflow: "Hidden",
          position: "relative",
          background: "#fff",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          className="pantalla"
          style={{
            height: "100px",
            width: "100%",
            backgroundImage: "url(/assets/images/pantalla.png)",
            backgroundSize: "contain",
            color: "#fff",
          }}
        ></div>
        <div
          className="titulo-mobile"
          style={{ textAlign: "center", color: "#2a4c94", fontWeight: "bold" }}
        >
          {docusUsuario === true &&
          (archivosEnCarpetaArborist.carpeta === Identificador + "/" ||
            archivosEnCarpetaArborist.carpeta === Identificador) ? (
            <p>Mi carpeta</p>
          ) : archivosEnCarpetaArborist.carpeta === "10 Documentación general" ? (
            "Documentación general"
          ) : (
            archivosEnCarpetaArborist.carpeta
          )}
        </div>
        <div className="contenedor-mobile">
          {archivosEnCarpetaArborist.archivos.map((archivo, index) => {
            if (archivo.includes(".")) {
              isFile = true;
            } else {
              isFile = false;
            }
            const extension = archivo.split(".").pop().toLowerCase();
            const icono = obtenerIconoPorExtension(extension, true);

            return (
              <div
                className={`carpetas-ficheros ${isFile ? "texto-agregado" : ""}`}
                style={{ fontSize: "1.6rem", color: "#2a4c94" }}
                key={index}
              >
                {icono}
                <div className="texto-carpeta" style={{ fontSize: "1.0rem" }}>
                  <p>{truncarCadena(archivo, 20)}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Emulador;
