const initialState = {
  total: 0,
  progreso: 0,
  finalizado: false,
};

const ProgressReducer = (state = initialState, action) => {
  console.log("ProgressReducer caca", action);
  switch (action.type) {
    case "SET_TOTAL":
      return { ...state, total: action.payload, progreso: 0 };
    case "UPDATE_PROGRESS":
      return { ...state, progreso: Math.min(state.progreso + action.payload, state.total) };
    case "END_PROGRESS":
      return { ...state, finalizado: action.payload };

    default:
      return state;
  }
};

export default ProgressReducer;
