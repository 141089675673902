

const diccionario_en_base = {
  hola: "Hello",
  empleados: "Employees",
  empleado: "Employee",
  empezar: "Start",
  empezada: "Started",
  organizacion: "Organization",
  estructura: "Structure",
  organizativa: "Organizational",
  crear: "Create",
  visualizar: "Visualize",
  modificar: "Modify",
  listados: "Lists",
  listado: "List",
  recursos: "Human Resources",
  resumen: "Summary",
  evaluados: "Evaluated",
  evaluado: "Evaluated",
  evaluaciones: "Evaluations",
  evaluacion: "Evaluation",
  evaluada: "Evaluated",
  pruebas: "Tests",
  areas: "Areas",
  evainiciadas: "Eval. initiated",
  abstencion: "Abstention",
  evarealizadas: "Eval. done",
  ranking: "Employee ranking by evaluation",
  nombre: "Name",
  Nombre: "Name",
  apellidos: "Last name",
  area: "Area",
  estado: "Status",
  fecha: "Date",
  resultado: "Result",
  todas: "All",
  año: "year",
  competencias: "competencies",
  destacadas: "outstanding",
  buenas: "good",
  normales: "normal",
  alejadas: "distant",
  submitida: "submitted",
  submitidas: "submitted",
  submitir: "submit",
  completas: "complete",
  periodos: "Periods",
  fomulario: "Form",
  actualizar: "Update",
  busqueda: "Search fields",
  rellenar: "Fill in",
  rellenar2: "Fill in",
  limpiarcampos: "Clear fields",
  seleccionado: "selected",
  hombre: "Man",
  mujer: "Woman",
  otros: "Others",
  genero: "Gender",
  cargo: "Position",
  acceso: "Access",
  herramienta: "tool",
  registro: "registration",
  telefono: "Telephone",
  inicio: "start",
  Bueno: "Good",
  Destacado: "Outstanding",
  Alejado: "Distant",
  Normal: "Normal",
  sin: "Without",
  finalizar: "finish",
  aviso0: "You must at least answer some questions of the questionnaire to start the evaluation",
  aviso1: "To be able to finish the evaluation, you must answer all the questions",
  aviso2: "To submit the evaluation, you must activate the 'Submit' button",
  aviso3: "EVALUATION SUBMITTED, any modification please contact the administrator",
  gerente: "Manager",
  observaciones: "Observations",
  aviso4: "Do you want to reopen the evaluation?",
  aviso5: "Do you want to submit the evaluation? Once submitted, you cannot modify it anymore",
  deLaOrganizacion: "FROM THE ORGANIZATION (CORE)",
  PERSONALES: "PERSONAL",
  delPuesto: "FROM THE POSITION",
  funcionales: "(FUNCTIONAL)",
  INTERPERSONALES: "INTERPERSONAL",
  delPuestoFuncionales: "FROM THE POSITION (FUNCTIONAL)",
  Email: "Email",
  Fecha_Creacion: "Creation date",
  Id: "Identifier",
  SOSBloqueo: "SOS",
  Codigo_seguridad: "Security code (max 5 digits)",
  Codigo_vecino: "Community registration code",
  Cp: "Postal Code",
  Poblacion: "City",
  Seguro_nombre: "Insurance Company",
  tooltip: "Insurance company associated with the Community",
  Poliza: "Policy",
  IBAN: "IBAN",
  Vecinos: "Neighbors",
  Pago_App: "Monthly APP payment",
  Cif: "CIF",
  tip_comunidad: "Insurance company associated with the Community",
  listaComunidades: "List of communities",
  Direccion: "Address",
  Provincia: "Province",
  estadoComunidad: "State",
  Altac365: "Alta c365",
  Fecha_Baja: "D. Low",
  Bloqueada: "Blocked",
  tip_Bloqueada: "Click to block/unblock user access to the app",
  tip_Alta: "Click to subscribe to Community 365",
  tip_Baja: "Click to upgrade or cancel contract",
  tituloBloquear: "Block community",
  tituloDesBloquear: "Unblock community",
  cuerpoBloquear: "Press accept to confirm community blocking",
  cuerpoDesBloquear: "Press accept to confirm community unlock",
  tip_EnvioComunidades: "Send notices to the previously selected communities",
  ENVIAR_AVISO: "Send notice",
  Buscar: "Search",
  Buscador: "Searcher",
  Inicio: "Home",
  Comunidades: "Communities",
  Incidencias: "Incidents",
  Gremios: "Suppliers",
  Roles: "Roles",
  Notificaciones: "Notifications",
  Despacho: "Dispatch",
  Documentos: "Documents",
  Usuarios: "Users",
  DescripcionMenuInicial: "Initial Menu Description",
  CIF: "CIF",
  Administracion: "Administration",
  AvisoLegal: "Legal Notice",
  PoliticaPrivacidad: "Privacy Policy",
  PoliticaCookies: "Cookies Policy",
  DescComunidades: "Community Management",
  DescIncidencias: "Incident Management",
  DescGremios: "Supplier management",
  DescRoles: "Roles management",
  DescUsuarios: "User management",
  DescNotificaciones: "Notification Management",
  DescAdministracion: "Dispatch Management",
  Juntas: "Meetings",
  Votaciones: "Voting",
  ZonasComunes: "Common Areas",
  Documentacion: "Documentation",
  Actividades: "Activities",
  Actividad: "Activity",
  DescJuntas: "Meeting Management",
  DescVotaciones: "Voting Management",
  DescZonasComunes: "Common Zones Management",
  DescDocumentacion: "Documentation Management",
  DescActividades: "Activities Management",
  Detalle: "Detail",
  Mostrando: "Showing",
  a: "to",
  de: "de",
  resultados: "results",
  HasSeleccionado: "You have selected",
  comunidades: "communities",
  paraAviso: "for sending NOTICE",
  comunidad: "community",
  Comunidad: "Community",
  exportarPdf: "Export to PDF",
  exportarCsv: "Export to CSV",
  filasPagina: "Rows per page",
  comunidadesSeleccionadas: "community/s selected for notification",
  CERRAR: "CLOSE",
  Restaurar: "Restore",
  tituloFormAvisos: "Sending notices",
  asunto: "Subject",
  textoAviso: "Text of the notice",
  URLopcional: "Optional URL",
  diagoloTitulo: "Unsubscribe from the community",
  diagoloBody: "Are you sure you want to unsubscribe from the community?",
  diagoloBody: "Are you sure you want to unsubscribe from the community?",
  cerrar: "Close",
  guardarCambios: "Save changes",
  diagoloBodyAviso: "Are you sure you want to send the notification?",
  diagoloTituloAviso: "Send notification",
  Guardar: "Save",
  CNAE: "CNAE",
  TlfnPrincipal: "Main phone",
  TlfnSecundario: "Secondary phone",
  Email1: "Email 1",
  Email2: "Email 2",
  idProveedor: "Supplier id",
  idComunidad: "Community Id",
  fechaInicioContrato: "Contract start date",
  fechaFinContrato: "Contract end date",
  observaciones: "Observations",
  sinresultados: "No results found",
  cancelar: "Cancel",
  aceptar: "Accept",
  Grupos: "Groups",
  AccesoATodo: "Access to all",
  Documentacion: "Documentation",
  ZonasReserva: "Reserve zones",
  JuntasYVotaciones: "Meetings and Voting",
  Observaciones: "Observations",
  Opciones: "Options",
  Acciones: "Actions",
  tip_BorrarRol: "Click to delete a role",
  confirmarBorradoRol: "You have marked a role for deletion, do you wish to continue?",
  tituloConfirmarBorradoRol: "Confirm deletion",
  Anadir: "Add",
  Proveedores: "Suppliers",
  SoloPara: "Only for:",
  Roles: "Roles",
  Numero: "Number",
  Portal: "Portal",
  Escalera: "Staircase",
  Piso: "Floor",
  Letra: "Letter",
  Inquilino: "Tenant",
  Multipropiedad: "Timeshare",
  Bloqueado: "Locked",
  Usuario: "User",
  AdministradorFincaColegiado: "Licensed Property Administrator",
  EmpleadoOficina: "Office Employee",
  RegistroHorario: "Logs",
  Ano: "Year",
  SeleccionaAno: "Select a year",
  Entrada: "Input",
  Salida: "Exit",
  Centro: "Plant",
  Horas: "Hours",
  Semana: "Week",
  Fecha: "Date",
  TotalHoras: "Total Hours",
  Minutos: "Minutes",
  Modificar: "Modify",
  Eliminar: "Delete",
  Filtrar: "Filter",
  tituloNuevoRegistroHorario: "New Time Record",
  confirmarBorradoRegistroHorario: "Do you want to delete the time record?",
  tituloConfirmarBorradoRistroHorario: "Delete time record",
  EnviarCheckEmail: "Send Check Emails",
  tituloModificarRegistroHorario: "Modify time record",
  MiCarpeta: "My Folder",
  ReseteoContrasena: "Reset Password",
  TextoReseteoContrasena:
    "The users password is going to be reset, at the moment the update is performed the user must access the application with the new password",
  AnadeContrasena: "Add the new password (At least 6 digits)",
  Borrar: "Delete",
  Todo: "All",
  Reservas: "Reservation",
  Despachos: "Dispatches",
  hasCambiadoDespacho: "You are now in the office",
  enviarAviso: "Send Notice",
  DNI: "DNI",
  descripcion: "Description",
  tipo: "Type",
  TextoAyudaActividades:
    "Select the activities about which you want your neighbors to be able to send incidences. Only the icons of the selected activities will appear on your neighbors cell phones. The administrators will always receive the requests by activity, if you want a specific provider to receive them also, associate it from the activity detail",
  EliminarUsuario: "Delete User",
  ConfirmarEliminarUsuario: "Do you want to delete the user?",
  DetalleActividad: "Activity detail",
  AnadirActividad: "Add activity",
  tip_BorrarActividad: "Click to delete an activity",
  confirmarBorradoActividad: "You have marked an activity for deletion, do you want to continue?",
  tituloConfirmarBorradoActividad: "Confirm deletion",
  Icono: "Icon",
  Si: "Yes",
  No: "No",
  cuerpoDesBloquearUsuario: "Do you want to unlock the user  ",
  tituloDesBloquearUsuario: "Unlock user ",
  cuerpoBloquearUsuario: "¿Deseas bloquear el usuario ",
  tituloBloquearUsuario: "Do you want to block the user ",
  Porcentaje: "Percentage",
  Proveedor: "Provider",
  SeleccionaProveedor: "Search and select a supplier...",
  UsuariosRegistradores: "Register users",
  FalloPago: "Payment failed, try again later",
  Gobierno: "Government Board",
  Administración: "Administration",
  Mantenimiento: "Maintenance",
  tituloEnvioCorrecto: "Result of the shipment",
  mensajeResultados: "Notice successfully sent to ${var1} recipients",
  tip_BorrarGremio: "Click to delete a guild",
  confirmarBorradoGremio: "You have marked a guild for deletion, do you want to continue?",
  tituloConfirmarBorradoGremio: "Confirm deleted",
  preciosSinIva: "All prices are without IVA.",
  preciosPorVecino:
    "Prices vary depending on the number of residents in the community. (This community has ${var1} neighbors)",
  preciosPorVecinoHistorico:
    "Prices vary depending on the number of residents in the community. (This community had ${var1} neighbors at the time of hiring)",
  CreadoPor: "Created by",
  estadoContrato: "State",
  asociarActividad: "The activity will now appear on the mobile phones of community users.",
  desasociarActividad:
    "The activity will no longer appear on the mobile phones of community users.",
  asociarTodasActividades:
    "Now all the activities will appear on the mobile phones of the community users",
  desasociarTodasActividades:
    "Now there will be no activity on the mobile phones of community users",
  EnvioAvisoEmail: "In addition to the notice, also send by email to those involved",
  Codigo: "Code",
  Tipo: "Type",
  Actividad: "Activity",
  Registrador: "Registration",
  tip_BorrarIncidencias: "Click to delete the incident",
  tip_compartirIncidencia: "Share/publish the incident",
  tip_ModificarRegistroHorario: "Click to edit the record",
  tip_BorrarRegistroHorario: "Click to delete the record",
  Compartir: "Share",
  red: "Red",
  yellow: "Yellow",
  green: "Green",
  incidenciaGreen: "Incident closed or cancelled",
  incidenciaYellow: "Incident in progress",
  incidenciaRed: "Incident not closed for 8 days or more",
  ENVIAR_AVISO_EMAIL: "Send Registration Email",
  adminDesk: {},
  SeleccionaComunidad: "Select a community",
  SeleccionaEstado: "Select a state",
  SeleccionaActividad: "Select an activity",
  SeleccionaProveedor2: "Select a supplier",
  Estado: "Status",
  ABIERTA: "OPEN",
  CERRADA: "CLOSED",
  CANCELADA: "CANCELLED",
  ENCURSO: "IN PROGRESS",
  SeleccionaTipo: "Select type",
  INCIDENCIA: "MALFUNCTION",
  SINIESTRO: "SINISTER",
  SOLICITUD: "REQUEST",
  CONSULTA: "QUERY",
  NuevaIncidencia: "New incident",
  DetalleIncidencia: "Incident detail",
  DetalleVotacion: "Detail of the vote",
  CodigoDespacho: "Dispatch code",
  CodigoComunidad: "Community code",
  SeleccionaRegistrador: "Select a registrar",
  DescripcionIncidencia: "Text to share (150 characters maximum)",
  DatosGuardadosCorrectamente: "Data saved correctly.",
  ErrorGuardarDatos: "Error saving data.",
  mensajePublicarIncidencia:
    "By activating this option the incident will be public for all residents of the community. Any modification and subsequent sending of notice will be made to all registered owners.",
  Fecha_Modificacion: "D. modification",
  EmailProveedor: "Supplier Email",
  TelefonoProveedor: "Supplier Phone",
  ActualizaDescripcionInterna: "Update the internal description",
  HistoricoDescripcionInterna: "History of the incident",
  TituloBorrarIncidencia: "Confirm deletion of incident",
  CuerpoBorrarIncidencia: "To mark the incident as deleted, press accept.",
  IncidenciaBorradaCorrectamente: "Incident deleted correctly",
  errorBorrarIncidencia: "Error deleting the incident",
  incidenciaBorrada: "Incident deleted",
  datosRefrescados: "Data refreshed",
  Documentacion: "Documentation",
  DocumentacionAdjunta: "Attached documentation",
  DocumentacionAdjuntaDesc: "Documents associated with the incident",
  arrastraArchivos: "Drag and drop your files here to upload them to the folder",
  TextoPropiedadDocumentos:
    "*All documents uploaded to C365 are property of the community, and will be transferred in case of change of administrator. If you consider that a document should not be transferred, please indicate it in the “Modify” option of that document,",
  textoConfirmarBorradoCarpeta: "Are you sure you want to delete the folder?",
  tituloConfirmarBorradoCarpeta: "Delete folder:",
  textoConfirmarBorradoFichero: "Are you sure you want to delete the file?",
  tituloConfirmarBorradoFichero: "Delete file:",
  ExitoBorradoFichero: "File deleted successfully",
  FalloBorradoFichero: "Failed to delete the file",
  Descargar: "Download",
  SeleccionaCompaniaSeguros: "Select Insurance Company",
  PolizaSeguros: "Insurance Policy",
  Direccion_es: "Address/s",
  PorcentajeTotalPropiedad: "% Total",
  TotalReservas: "Total Reserves",
  PorcPropiedad: "% Ownership",
  ViviendaInquilino: "Housing with Tenant",
  ViviendaRepLegal: "Housing with Legal Rep",
  propietario: "Owner",
  inquilino: "Tenant",
  representantelegal: "Legal Representative",
  ViviendasTipo: "Housing / Type",
  SinViviendas: "Without housing",
  AltaCorrecta: "Deregistered correctly.",
  FalloBajaComunidad: `Failure to cancel the community`,
  ComunidadNoAlta: "The community is not high.",
  ErrorGuardadoDatos: "Error saving data.",
  ErrorDatosExistentes: "The activity ${var1} already exists, please use another name.",
  DatosActualizadosCorrectamente: "Data updated correctly",
  ActividadBorradaCorrectamente: "Activity deleted successfully",
  ActividadExistente: "The activity ${var1} already exists, please use another name.",
  FechaAlta: "Date of registration",
  CodigoVecino: "Neighbor Code",
  CodigoPostal: "Postal Code",
  NumeroVecinos: "Number of Neighbors",
  Siniestros: "Claims",
  ModificacionClientes: "Customer Modification",
  ConsultasAdmin: "Admin Queries",
  ConsultasSeguros: "Insurance consultations",
  Categoria: "Category",
  Alta: "High",
  ConfirmarBajaComunidad:
    "Are you sure you want to delete the community with id ${var1} from C365?",
  MaximoArchivos: "You can only upload up to a maximum of ${var1} files",
  TipoArchivoNoPermitido: "File type not allowed: ${var1}",
  ArchivoTamañoMaximoSuperado: "File ${var1} exceeds the maximum allowed size of ${var2} MB",
  ArchivosSubidosCorrectamente: "Files uploaded correctly.",
  NombreCarpetaVacio: "The folder name cannot be empty.",
  MismoNombreArchivoMismoNivel: "You cannot give the same name to a document of the same level",
  CarpetaCreadaCorrectamente: "Folder created successfully.",
  ErrorCrearCarpeta: "Error creating folder.",
  CarpetaMovidaCorrectamente: "File/Folder moved correctly.",
  ErrorMoverCarpeta: "Error moving the file/folder.",
  Carpeta03NoBorrar: "Folder 03 Owners Meetings cannot be deleted/modified",
  GuardarCambiosModificar: "To save the changes you have to modify the file",
  ErrorCambiarTransferibilidad: "Error changing transferability",
  NombreCambiadoCorrectamente: "Name changed successfully.",
  ErrorCambiarNombre: "Error changing the name.",
  TipoArchivoNoPermitido: "File type not allowed",
  DocumentosComunidad: "Community Documents",
  Busqueda: "Search",
  CarpetaActual: "Current folder:",
  NombreCarpeta: "Folder name",
  Descargar: "Download",
  Borrar: "Delete",
  EnviarAviso: "Send notice",
  AvisoDocumentosSubidosC365:
    "All documents uploaded to C365 are the property of the community, and will be transferred in the event of a change of administrator. If you consider that a document should not be transferred, please indicate it in the “Modify” option of that document",
  SeleccionarCarpetaMostrarDetalle: "Select a folder to show its details",
  TipoArchivoNoPermitido: "File type not allowed: ${var1}",
  TodoOk: "everything ok",
  GremioCreadoConecta: "The guild has been created by Conecta or has no associated activity",
  ProveedorBaja: "The supplier has been canceled",
  CasoNoCompletado: "case not contemplated",
  FechaAlta: "High Date",
  FechaBaja: "Low Date",
  GremioBorradoCorrectamente: "Guild deleted correctly",
  Comus: "comus",
  CambioEstado: "State change",
  NotificacionUsuarioAceptarOCancelar:
    "You can send notification to the registering user, the administrator and an e-mail to the supplier about the update of the incident (IN PROGRESS) or (OPEN) press ACCEPT or CANCEL",
  CambioEstadoIncidencia: "Incident status change",
  CambioEstadoIncidenciaCodigo:
    "The status of the incident with code ${var1} has been changed from ${var2} to ${var3}",
  CambioEstadoIncidenciaCodigoCerrada:
    "The status of the incident with code ${var1} has been closed ",
  ArchivoExcedeTamano: "The file exceeds the maximum allowed size of ${var1} MB",
  CambioTipo: "Change type",
  NotificacionIncidenciaAceptarOCancelar:
    "You can send notification to the registering user, the administrator and an e-mail to the provider about the change of the incident type to ACCEPT, press “ACCEPT” or “CANCEL”.",
  CambioTipoIncidencia: "Incident type change",
  CambiadoTipoIncidenciaCodigo: "The type of the incident with code has been changed ",
  CambiadoTipoAveriaCodigo:
    "You can send notification to the registering user, the administrator and an e-mail to the supplier about the change of the incident type to BREAKDOWN, press “ACCEPT” or “CANCEL”.",
  FechaModificacion: "Modification date",
  FechaCreacion: "Creation date",
  NombreRegistrador: "Register Name",
  ApellidoRegistrador: "Register Surname",
  RolExiste: "The role ${var1} already exists, please use another one.",
  DatosRefrescados: "Data refreshed",
  RolBorradoCorrectamente: "Role deleted correctly",
  LimpiarBusqueda: "Clear search",
  LimpiarSeleccionado: "Clear selected",
  NoHayRoles: "There are no roles",
  SeleccionarTodos: "Select all",
  SeleccionarTodosFiltrado: "Select all (filtering)",
  RolSeleccionado: "Selected role...",
  EmailYaExiste: "Email already exists",
  DNIYaExiste: "The DNI already exists",
  CompletaCamposObligatiorio: "Please complete all required fields.",
  NoValidoIntroduceCorrecto: "The DNI it is not valid, enter a correct one",
  FotoBorradaCorrectamente: "Photo deleted correctly.",
  FotoGuardadaCorrectamente: "Photo saved correctly.",
  AnadirUsuario: "Add User",
  FechaIncorrecta: "The date is incorrect.",
  NoFechasVacias: "You cannot leave one of the dates empty",
  HorasMinutos: "Hours minutes",
  RegistroHorarioBorrado: "Time record deleted successfully",
  ErrorBorrandoRegistro: "Error deleting record",
  HoraSalidaEntradaIncorrecta: "The departure time cannot be less than the arrival time",
  TodosRolesSeleccionados: "All roles have been selected",
  SeleccionarRoles: "Select roles",
  RegistroInquilinoCambiado: "Tenant registration changed.",
  ErrorGuardarDatos: "Error saving data",
  UsuarioEliminadoCorrectamente: "User successfully deleted.",
  DNIExisteOtroUsuario: "The DNI already exists in another user",
  EmailExisteOtroUsuario: "The Email already exists in another user",
  ContrasenaActualizadaNuevaContrasena: "The password has been updated. New Password:",
  ErrorCambiarContrsena: "Error changing password.",
  ErrorEnviandoEmailConfirmacion: "Error sending verification email: ",
  EnviadoCorreoConfirmacionA: "The verification email has been sent to",
  AltaC365: "AltaC365",
  CodigoCompletisimo: "codeCompletisimo",
  VotoBorradoCorrectamente: "Vote deleted correctly",
  TipoVotacion: "Voting type",
  Publicar: "Publish",
  AperturaVotacion: "Open voting",
  ResultadoPublicado: "Published result",
  PublicadoPorPorciento: "Published By %",
  AltaComunidadConfigurar:
    "You already have this community registered in c365, follow the following steps to configure your community:",
  BienvenidoC365: "Welcome to c365!",
  GeneraCodigoVecinos: "Genera un código para que tus vecinos puedan registrarse en la app.",
  CompletaDatosProveedoresGestionIncidencias:
    "Complete the data of your contracted suppliers to manage incidents and claims.",
  SubeDoucmentacionComparte: "Upload the documentation you want and share it with the owners.",
  PreparaVotacionesJuntas: "Prepare votes or meetings when necessary.",
  ConfiguraZonasComunes:
    "Configure the common areas (if you have contracted the Common Areas Pack).",
  HabilitaEmleadosFichar:
    "Enable employees so that they can clock in (if you have contracted the Time Control Pack).",
  MarcarEnviarAviso: "Mark to send notice",
  MarcarEnviarAvisoRegistroVecino: "Dial to send notice or send neighbor registration code",
  UsuariosActivosComunidades: " Active Users / Communities",
  UsuariosComunidad: "Users Community",
  ComunidadesActivasUsuarios: "Comunities Actives / Users",
  ComusUsuarios: "Comus Users",
  IncidenciasEstados: "Incidents/Status",
  EstadoNumero: "State/Number",
  IncidenciasAverias: "Incidents Breakdowns/Types",
  IncidenciasTodas: "All incidents",
  CodigoDespacho: "Dispatch code",
  Refrescar: "Refresh",
  Volver: "Return",
  AltaDesde: "Registration from",
  AltaHasta: "Registration up to",
  NOTIFICACIONES: "NOTIFICATIONS",
  CUADRODEMANDOS: "SCORECARD",
  TODAS: "ALL",
  ACTIVAS: "ACTIVES",
  TODOS: "ALL",
  ACTIVOS: "ACTIVES",
  ABIERTA: "OPEN",
  ENCURSO: "IN PROGRESS",
  CANCELADA: "CANCELLED",
  CERRADA: "CLOSED",
  Desde: "From",
  Hasta: "Until",
  CerrarSesion: "Sign off",
  DerechoDeReservas: "Reservations Rights",
  RazonSocial: "Business name",
  Correo: "Email",
  PersonaDeContacto: "Contact person",
  DetalleDespacho: "Office details",
  ResultadoPublicadoPorPorciento: "Result published by %",
  ErrorNoArchivoBorrar: "Error, file to be deleted has not arrived",
  PUBLICADA: "PUBLISHED",
  NOPUBLICADA: "NO PUBLISHED",
  SINOABS: "YES/NO/ABS",
  OPCIONES: "OPTIONS",
  NuevaOpcionDeVotacion: "New voting option",
  ElFicheroExisteNoModificar: "The file ${var1} already exists and cannot be modified",
  EnviarCambiosRealizados: "The changes made will be sent to the user/s and administrator",
  CambiarEstadoCerradaNoModificar:
    "Do you want to change the status to CLOSED? If you change it you will no longer be able to modify the incident",
  IncidenciaActualizadaCorrectamente: "The issue has been updated successfully",
  IncidenciaCerradaCambioAdministrador:
    "The incident is closed, if you want to make any changes, please contact the administrator, thank you",
  IncidenciaCanceladaCambioAdministrador:
    "The incident has been cancelled, if you wish to make any changes, please contact the administrator, thank you",
  BorrarEstadoAbierta: "In order to delete the status of the incident it must be OPEN",
  ArrastraFicheroCargarloVentana: "Drag the file or click here to load it from the window",
  CargarFicheroAdjuntarFoto: "Click here to upload a file or attach a photo",
  AnadeComentariosEnvialo: "Add comments and send them",
  TextoAnadir: "Text to add",
  NombreZona: "Zone name",
  DescripcionIncidencia: "Incident Description",
  UltimaModificacion: "Last modification",
  GremioResponsable: "Responsible Guild",
  ImagenAsociada: "Associated image: ",
  Gremio: "Guild: ",
  Incidencia: "Incidence: ",
  CentrosTrabajo: "Work centers",
  DocumentacionGeneral: "General documentation",
  TodosCorreos: "All emails",
  RecibirIncidencias: "Receive incidents",
  RecibirSiniestros: "Receive claims",
  RecibirModificacionClientes: "Receive Modification from Clients",
  RecibirConsultaAdmin: "Receive Administrator Query",
  RecibirConsultaSeguros: "Receive Insurance Consultation",
  CategoriaUsuarioAdministrador: "User-Administrator Category",
  Rol: "Role",
  Fichar: "File",
  ResetearContrasena: "Reset password",
  VerificarEmail: "Verify email",
  Eliminar: "Eliminate",
  DetallesUsuario: "User Details",
  FechaUltimoAcceso: "Last Access Date",
  FechaCreacion: "Creation date",
  AnadirRegistro: "Add record",
  HorasEntrada: "Entry Hours",
  HorasSalida: "Departure Hours",
  Latitud: "Latitude",
  Longitud: "Length",
  Distancia: "Distance",
  VotacionaBorradaCorrectamente: "Vote deleted successfully",
  AnadirCentroDeTrabajo: "Add Work Center",
  CodigoAdministrador: "Administrator code",
  DatosCentroDeTrabajo: "Work Center Data",
  CentrosTrabajoDespacho: "Office work centers",
  UsuariosDespacho: "Office users",
  CentroTrabajoCerradoCorrectamente: "Work center deleted successfully",
  tip_BorrarCentroTrabajo: "Click to delete the work center",
  centroDeTrabajoBorrado: "Deleted workcenter",
  CuerpoBorrarCentroTrabajo: "To delete the work center press accept.",
  TituloBorrarCentroTrabajo: "Confirm deletion of the work center",
  Administrador: "Administrator",
  Oficial: "Officer",
  Auxiliar: "Auxiliary",
  Archivada: "Archived",
};

export const diccionario_en = new Proxy(diccionario_en_base, {
  get(target, prop) {
    // If prop is a Symbol (like Symbol.toStringTag), return the default behavior
    if (typeof prop === "symbol") {
      return Reflect.get(target, prop);
    }

    // Otherwise, return the translated value or the fallback
    return prop in target ? target[prop] : `${String(prop)} [sin trad.]`;
  }
});

export const tableLocation_en = {
  pagination: {
    labelRowsSelect: "Rows per page",
    labelDisplayedRows: "{from}-{to} of {count}",
    firstAriaLabel: "First page",
    firstTooltip: "First page",
    previousAriaLabel: "Previous page",
    previousTooltip: "Previous page",
    nextAriaLabel: "Next page",
    nextTooltip: "Next page",
    lastAriaLabel: "Last page",
    lastTooltip: "Last page",
  },
  toolbar: {
    searchTooltip: "Search",
    searchPlaceholder: "Search",
    nRowsSelected: "{0} row(s) selected",
  },
  header: {
    actions: "Actions",
  },
  body: {
    emptyDataSourceMessage: "No data available",
    filterRow: {
      filterTooltip: "Filter",
    },
    editRow: {
      saveTooltip: "Save",
      cancelTooltip: "Cancel",
      deleteText: "Are you sure you want to delete this row?",
    },
    addTooltip: "Add row",
    deleteTooltip: "Delete row",
  },
  edit: {
    saveTooltip: "Save",
    cancelTooltip: "Cancel",
    deleteText: "Are you sure you want to delete this row?",
  },
  group: {
    deleteTooltip: "Delete group",
    editTooltip: "Edit group",
    addTooltip: "Add group",
  },
};
