import React, { useState } from "react";
import { useStore } from "react-redux";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Grid, TextField } from "@mui/material";
import enviarAvisos, { cargarResultado } from "app/library/enviarAvisos";
import { useFirebase } from "../../../app/firebase/context";
import useAuth from "app/hooks/useAuth";
import ClipLoader from "react-spinners/ClipLoader";
import getCustomToastOptions from "app/components/helpers/getCustomToastOptions";
import { toast } from "react-toastify";
import { IoInformationCircleOutline } from "react-icons/io5";
import Tooltip from "react-tooltip-lite";
import Switch from "react-switch";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "./../../constants/routes";

const FormularioEnvioAvisos = ({
  comunidadesSeleccionadas,
  abrirDiagolo,
  cerrarDiagolo,
  archivo = "",
  pathCarpeta = "",
  roles = [],
  usuariosSeleccionados = null,
  idVotacion = null,
}) => {
  const store = useStore();
  const lengu = store.getState().lenguajes;
  const diccionario = lengu.diccionario;
  const firebase = useFirebase();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [confEmail, setConfEmail] = useState(false);
  const [state, setState] = useState();
  const [mostrarResultados, setMostrarResultados] = useState(false);
  const [mensajeResultados, setMensajeResultados] = useState("");
  const [usuariosTotal, setUsuariosTotal] = useState([]);
  const [idJunta, setIdJunta] = useState(null);
  const navigate = useNavigate();
  // Estado para los inputs
  const [formularioState, setFormularioState] = useState({
    asunto: "",
    avisotexto: "",
    url: "",
  });

  const limiteCaracteres = 1500;

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "avisotexto" && value.length > limiteCaracteres) {
      console.log("Se superó el límite de caracteres");
      return; // Evitar que se actualice el estado si se supera el límite
    }

    setFormularioState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const enviarAviso = () => {
    console.log("Usuarios seleccionados", usuariosSeleccionados);
    // return;
    setLoading(true);
    var comus = Array.isArray(comunidadesSeleccionadas)
      ? comunidadesSeleccionadas.map((value) => value.Id)
      : [];

    //user.Nombre = user.datosUsuarioBD.Nombre;
    // user.Apellido = user.datosUsuarioBD.Apellido;

    // Enviar confirmacion por email a los implicados
    const ConfirmacionPorEmail = confEmail;

    new Promise((resolve, reject) => {
      enviarAvisos(
        comus,
        formularioState.avisotexto,
        formularioState.asunto,
        formularioState.url,
        firebase,
        pathCarpeta,
        archivo,
        "otro",
        [],
        user, // Usuario logeado
        user.despacho, // Despacho
        roles,
        idVotacion,
        null,
        ConfirmacionPorEmail,
        usuariosSeleccionados,
        setIdJunta
      ).then((re) => {
        resolve(re);
      });
    })
      .then((data2) => {
        if (data2) {
          const registro = cargarResultado(data2, [], firebase, user);

          console.log("Resultado envío", registro);

          setState({
            resultado_aviso: registro.archivo_final,
            avisos_enviados: true,
            open: false,
            mostrarTabla: true,
            usuariosTotal: registro.usuariosTotal,
            texto_envio: "",
            texto_titulo: "",
            texto_url: "",
          });

          toast.success(
            diccionario.AvisoEnviadoCorrectamenteA +
              registro.usuariosTotal.length +
              diccionario.destinatarios,
            getCustomToastOptions(toast, "info")
          );

          setMensajeResultados(
            diccionario.mensajeResultados.replace("${var1}", registro.usuariosTotal.length)
          );
          setUsuariosTotal(registro.usuariosTotal);
        } else {
          toast.error(diccionario.ProblemaNoPodidoEnviar, getCustomToastOptions(toast, "error"));

          // this.setState({ Categorias: [] });
        }
        //cerrarAviso();
        setMostrarResultados(true);
        setLoading(false);
      })
      .catch((reason) => {
        setLoading(false);
        console.log("Error al enviar aviso", reason);
        toast.error(diccionario.FalloEnviarAviso + reason, getCustomToastOptions(toast, "error"));
      });
  };

  return (
    <div className="formulario-aviso">
      <div style={{ display: loading === true ? "block" : "none" }}>
        <ClipLoader
          size={100}
          color={"#123abc"}
          loading={loading}
          cssOverride={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          speedMultiplier={1}
        />
      </div>
      <div
        style={{
          display: loading !== true && mostrarResultados === true ? "block" : "none",
        }}
      >
        <Grid container spacing={3}>
          <Grid className="Buscador" item lg={12} md={12} sm={12} xs={12} sx={{ mt: 0 }}>
            <h3>{diccionario.tituloEnvioCorrecto}</h3>
            {mensajeResultados}
          </Grid>
        </Grid>
        <div className="botones-modal-footer">
          {idJunta && (
            <button
              className="btn btn-outline-primary mt-3 px-4 enviar "
              onClick={() => navigate(`${ROUTES.DETALLE_NOTIFICACION}/${idJunta}`)}
            >
              {diccionario.verDetalleEnvio}
            </button>
          )}
          <button className="btn btn-outline-primary mt-3 px-4 enviar " onClick={cerrarDiagolo}>
            {diccionario.cerrar}
          </button>
        </div>
      </div>
      <div
        style={{
          display: loading !== true && mostrarResultados !== true ? "block" : "none",
        }}
      >
        <ValidatorForm onSubmit={enviarAviso}>
          <Grid container spacing={3}>
            <Grid className="Buscador" item lg={12} md={12} sm={12} xs={12} sx={{ mt: 0 }}>
              <p>
                <label>{diccionario.asunto}</label>
              </p>
              <TextValidator
                id="asunto"
                name="asunto"
                type="text"
                value={formularioState.asunto}
                onChange={handleChange}
                validators={["required"]}
                errorMessages={["El asunto es obligatorio"]}
              />
            </Grid>
            <Grid className="Buscador" item lg={12} md={12} sm={12} xs={12} sx={{ mt: 0 }}>
              <p>
                <label>{diccionario.textoAviso}</label>
              </p>
              <TextValidator
                id="avisotexto"
                name="avisotexto"
                type="text"
                multiline
                rows={4}
                value={formularioState.avisotexto}
                onChange={handleChange}
                validators={["required"]}
                errorMessages={["El cuerpo del mensaje es obligatorio"]}
                InputProps={{
                  style: {
                    resize: "none",
                  },
                }}
              />
            </Grid>
            <Grid className="Buscador" item lg={12} md={12} sm={12} xs={12} sx={{ mt: 0 }}>
              <p>
                <label>{diccionario.URLopcional}</label>
              </p>
              <TextField name="url" value={formularioState.url} onChange={handleChange} />
            </Grid>
            <Grid className="Buscador" item lg={12} md={12} sm={12} xs={12} sx={{ mt: 0 }}>
              <div
                className="traspasble"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  margin: "0px",
                  marginTop: "10px",
                }}
              >
                <Tooltip
                  content={
                    "Si eliges sí se enviará un aviso por email a todos los implicados aparte de la notificación a los usuarios de la app de la comunidad."
                  }
                >
                  {" "}
                  <IoInformationCircleOutline
                    color="blue"
                    style={{
                      fontSize: "16px",
                      marginRight: "2px",
                      marginBottom: "2px",
                    }}
                  />
                </Tooltip>

                <p style={{ margin: "0px", marginRight: "10px" }}> {diccionario.EnvioAvisoEmail}</p>
                <div></div>
                <Switch
                  checked={confEmail}
                  onChange={() => setConfEmail(!confEmail)}
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  handleDiameter={10}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={15}
                  width={28}
                  styl
                />
                <p> {confEmail ? diccionario.Si : diccionario.No}</p>
              </div>
            </Grid>
          </Grid>
          <div className="botones-modal-footer">
            <button className="btn btn-outline-primary mt-3 px-4 enviar " onClick={cerrarDiagolo}>
              {diccionario.cancelar}
            </button>
            <button
              disabled={formularioState.asunto === "" || formularioState.avisotexto === ""}
              type="submit"
              className="btn btn-outline-primary mt-3 px-4 enviar "
              onClick={abrirDiagolo}
            >
              {diccionario.Enviar}
            </button>
          </div>
        </ValidatorForm>
      </div>
    </div>
  );
};

export default FormularioEnvioAvisos;
