import React, { Fragment } from "react";
import Stack from "@mui/material/Stack";
import { Paper, Modal, Box, Step } from "@material-ui/core";
import { SettingsEthernetSharp } from "@material-ui/icons";

const ModalComponent = ({
  planHistorico,
  setPlanHistorico,
  step,
  setStep,
  tituloModal,
  cuerpoModal,
  isModalOpen,
  setModalOpen,
  alturaCustom = "75%",
  anchuraCustom = "80%",
  alturaContenido = "",
  closeEnvios = false,
  top = "50%",
}) => {
  const handleClose = () => {
    setModalOpen(false);
    if (closeEnvios === false) {
      setStep(1);
    }
  };

  return (
    <Fragment>
      <Modal
        width="80%"
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ zIndex: "100" }}
      >
        <Box
          className="modal-popeup"
          sx={{
            position: "absolute",
            top: top,
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: anchuraCustom,
            //  height: alturaCustom,
            bgcolor: "background.paper",
            border: "0px",
            boxShadow: 24,
            p: 4,
            borderRadius: "2px",
            zIndex: "1",
          }}
        >
          <header
            className="modal-header-pop"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h5>{tituloModal}</h5>
          </header>
          <Stack spacing={3}>
            <Paper
              style={{
                padding: 16,
                margin: -32,
                overflow: "auto",
                boxShadow: "none",
                height: alturaContenido,
              }}
            >
              <div
                style={{
                  maxHeight: "calc(100vh - 150px)", // Adjust this value as needed
                  overflowY: "auto",
                }}
              >
                {cuerpoModal}
              </div>
            </Paper>
          </Stack>
          <button onClick={handleClose} className="btn btn-secondary btn-close cerrar-top"></button>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default ModalComponent;
