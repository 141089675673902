import { combineReducers } from "redux";
import EcommerceReducer from "./EcommerceReducer";
import NavigationReducerSlice from "./NavigationReducer";
import NotificationReducer from "./NotificationReducer";
import LenguajeReducerSlice from "./LenguajeReducer";
import ProgressReducer from "./ProgressReducer"; // Importar el reducer de progreso

const RootReducer = combineReducers({
  notifications: NotificationReducer,
  navigations: NavigationReducerSlice,
  ecommerce: EcommerceReducer,
  lenguajes: LenguajeReducerSlice,
  progress: ProgressReducer,
});

export default RootReducer;
