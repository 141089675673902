import React from "react";

export const ContratoContenido = ({
  ContenidoPlan,
  ContenidoFormaPago,
  ContenidoIban,
  PrecioSelected,
}) => {
  const fechaActual = new Date().toLocaleString("es-ES", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return (
    <div className="summary">
      <div className="summary-header">
        <h1>Resumen de Servicios Contratados</h1>
        <p>Fecha: {fechaActual}</p>
      </div>

      <div className="summary-body">
        <p>A continuación, se detalla un resumen de los servicios contratados</p>

        <ul>
          <li>
            <strong>Plan seleccionado:</strong> {ContenidoPlan}
          </li>
          <li>
            <strong>Forma de pago:</strong>{" "}
            {ContenidoFormaPago.charAt(0).toUpperCase() + ContenidoFormaPago.slice(1)}
          </li>
          <li>
            <strong>IBAN asociado:</strong>{" "}
            {ContenidoIban.charAt(0).toUpperCase() + ContenidoIban.slice(1)}
          </li>
          <li>
            <strong>Precio total:</strong> {PrecioSelected} €
          </li>
        </ul>

        <p>
          Si realizar modificaciones, puedes retroceder en el proceso de contratación y volver al
          paso correspondiente. con nosotros.
        </p>
      </div>

      <div className="summary-footer">
        <p>Atentamente,</p>
        <p>C365</p>
      </div>
    </div>
  );
};
